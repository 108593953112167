import React, { useState } from 'react';

const FloatingChatButton = () => {
    const [chatActive, setChatActive] = useState<boolean>(false);

    return (
        <>
            {chatActive && (
                <div className="fixed z-50 rounded-lg overflow-hidden w-[300px] h-[500px] xl:w-[400px] xl:h-[600px] bg-white bottom-[17vh] right-0 mx-[calc((100vw-300px)/2)] sm:mx-0 sm:bottom-28 sm:right-8 shadow-xl">
                    <iframe
                        src="https://delfi.euda.com.ar/webchat?client=Soporte"
                        style={{ width: '100%', height: '100%' }}
                    />
                </div>
            )}
            <div className="btn-chatbot-container">
                <p className="chatbot-label">Hola, soy Ceci ¿Tenés alguna duda?</p>
                <button onClick={() => setChatActive(!chatActive)} className="btn-chatbot"></button>
            </div>
        </>
    );
};

export default FloatingChatButton;
