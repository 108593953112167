export interface BaseRequest {
    url: string;
    body?: Object;
    headers?: Object;
}

//if you have a better idea to unify the 401 redirection, you are welcome.
export interface BaseResponse<T> {
    body: T;
    error?: any;
    errorCode?: any;
    sessionExpired: boolean;
}

export interface HttpRequest {
    get<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>>;
    post<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>>;
    put<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>>;
    patch<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>>;
    delete<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>>;
}

export class FetchHttpRequest implements HttpRequest {
    async get<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>> {
        return await this.basicRequest<T>(
            baseRequest.url,
            'GET',
            baseRequest.body,
            baseRequest.headers
        );
    }

    async post<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>> {
        return await this.basicRequest<T>(
            baseRequest.url,
            'POST',
            baseRequest.body,
            baseRequest.headers
        );
    }

    async put<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>> {
        return await this.basicRequest<T>(
            baseRequest.url,
            'PUT',
            baseRequest.body,
            baseRequest.headers
        );
    }

    async patch<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>> {
        return await this.basicRequest<T>(
            baseRequest.url,
            'PATCH',
            baseRequest.body,
            baseRequest.headers
        );
    }

    async delete<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>> {
        return await this.basicRequest<T>(
            baseRequest.url,
            'DELETE',
            baseRequest.body,
            baseRequest.headers
        );
    }

    private async basicRequest<T>(
        url: string,
        method: string,
        body?: Object,
        headers?: Object
    ): Promise<BaseResponse<T>> {
        const response = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: Object.entries(
                headers ? { ...headers, ...this.defaultHeaders() } : this.defaultHeaders()
            )
        });

        if (!(response.status >= 200 && response.status < 300)) {
            const sessionExpired = response.status == 401;
            const result = await response.json();

            return {
                body: {} as T,
                error: result?.message || 'Ha ocurrido un error.',
                errorCode: result?.code,
                sessionExpired
            };
        }

        const resBody = response.status !== 204 ? await response.json() : undefined;
        return {
            body: resBody,
            sessionExpired: false
        };
    }

    defaultHeaders() {
        return {
            'Content-Type': 'application/json'
        };
    }
}
