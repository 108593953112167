import { ClientPlan } from '@prisma/client';

//TODO, extend from BaseWrapper.
export class ClientPlanWrapper {
    public clientPlan: ClientPlan;

    constructor(clientPlan: ClientPlan) {
        this.clientPlan = clientPlan;
    }

    static getAdminPlan(): ClientPlanWrapper {
        return new ClientPlanWrapper({
            id: 'admin',
            name: 'Admin',
            botMessageLimit: Number.MAX_SAFE_INTEGER,
            documentSizeLimit: Number.MAX_SAFE_INTEGER,
            documentsLimit: Number.MAX_SAFE_INTEGER,
            canEditClientName: true,
            canEditClientLogo: true,
            canEditTimeOffset: true,
            canEditWhatsAppNumber: true,
            canEditFacebookPageId: true,
            canEditBotContext: true,
            canEditBotContextConfig: true,
            canEditBotTemperature: true,
            canEditBotIcon: true,
            canEditBotModel: true,
            canEditBotName: true,
            canEditStatusText: true,
            canEditCallToAction: true,
            canEditInputPlaceholder: true,
            canEditContactCenterDisabledText: true,
            canEditShowSourceDocs: true,
            canEditPresetMessages: true,
            canEditReportTemplate: true
        });
    }

    areValidFiles(files: { size: number }[]) {
        return files.every(
            (file) => this.byteToKilobytes(file.size) <= this.clientPlan.documentSizeLimit
        );
    }

    hasFileMessage(): boolean {
        return (
            this.clientPlan.documentSizeLimit !== Number.MAX_SAFE_INTEGER ||
            this.clientPlan.documentsLimit !== Number.MAX_SAFE_INTEGER
        );
    }

    getFileMessage(): string {
        let message = '';
        if (this.clientPlan.documentSizeLimit !== Number.MAX_SAFE_INTEGER) {
            message += `Tamaño máximo de archivo PDF: ${
                this.clientPlan.documentSizeLimit / 1000
            } MB. `;
        }
        if (this.clientPlan.documentsLimit !== Number.MAX_SAFE_INTEGER) {
            message += `Máximo de documentos: ${this.clientPlan.documentsLimit} archivo/s PDF. `;
        }
        return message;
    }

    getFilesLimit(): number {
        return this.clientPlan.documentsLimit;
    }

    getFileSizeLimit(): number {
        return this.clientPlan.documentSizeLimit;
    }

    hasBotMessageLimit(): boolean {
        return this.clientPlan.botMessageLimit !== Number.MAX_SAFE_INTEGER;
    }

    isAdmin(): boolean {
        return this.clientPlan.name === 'Admin' && this.clientPlan.id === 'admin';
    }

    isFree(): boolean {
        return this.clientPlan.name === 'FREE';
    }

    private byteToKilobytes(bytes: number): number {
        return bytes * 0.001;
    }
}
