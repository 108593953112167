import { NextRouter } from 'next/router';

import { Routes } from '@/enums/routes.enum';

export class NavigationRouter {
    route: NextRouter;

    constructor(route: NextRouter) {
        this.route = route;
    }

    to(route: Routes, params?: Record<string, string>) {
        const parsed = new URLSearchParams(params).toString();
        this.route.push(route + (parsed.length ? '?' + parsed : ''));
    }

    toLogin() {
        this.to(Routes.LOGIN);
    }

    toIndex() {
        this.to(Routes.INDEX);
    }

    getPath() {
        return this.route.pathname;
    }

    getQuery() {
        return this.route.query;
    }

    toAuthMessage(title: string, text: string) {
        this.to(Routes.AUTH_MESSAGE, {
            title,
            text
        });
    }
}
