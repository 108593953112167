import { MessageType, PresetMessage } from '@prisma/client';

import { PresetMessageFormData } from '../pages/clients/preset-form/utils/preset-form-data-manager';
import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { rollbar } from '../utils/rollbar';

export interface PresetMessagesService {
    getPresetMessages(clientId: string): Promise<BaseResponse<PresetMessage[]>>;
    updatePresetMessages(
        clientId: string,
        toCreate: PresetMessageFormData[],
        toUpdate: (PresetMessageFormData & { id: string })[],
        toDelete: string[]
    ): Promise<BaseResponse<PresetMessage[]>>;
}

export class PresetMessagesServiceImp implements PresetMessagesService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        this.httpRequest = httpRequest;
    }

    private apiPresetMessagesRoute(clientId: string) {
        return `api/client/${clientId}/preset-messages`;
    }

    private async tryRequest<T>(req: () => Promise<BaseResponse<T>>) {
        try {
            const response = await req();
            if (response.error) console.error(response.error);
            return response;
        } catch (error) {
            console.error('Error during request:', error);
            rollbar.error('Error during request: ', { custom: { error } });

            return { body: {} as T, error: error, sessionExpired: false };
        }
    }

    async getPresetMessages(clientId: string): Promise<BaseResponse<PresetMessage[]>> {
        const request = () =>
            this.httpRequest.get<PresetMessage[]>({
                url: generateUrl(this.apiPresetMessagesRoute(clientId))
            });
        return this.tryRequest<PresetMessage[]>(request);
    }

    async updatePresetMessages(
        clientId: string,
        toCreate: PresetMessageFormData[],
        toUpdate: (PresetMessageFormData & { id: string })[],
        toDelete: string[]
    ): Promise<BaseResponse<PresetMessage[]>> {
        const request = () =>
            this.httpRequest.post<PresetMessage[]>({
                url: generateUrl(this.apiPresetMessagesRoute(clientId)),
                body: {
                    create: toCreate.map((message) => this.sanitizeMessageByType(message, false)),
                    update: toUpdate.map((message) => this.sanitizeMessageByType(message, true)),
                    delete: toDelete
                }
            });
        return this.tryRequest<PresetMessage[]>(request);
    }

    private sanitizeMessageByType(
        message: PresetMessageFormData,
        withId: boolean
    ): Omit<PresetMessageFormData, 'key'> {
        return {
            id: withId ? message.id : undefined,
            type: message.type,
            position: message.position,
            text: message.text,
            ...this.particularMessageConfig(message)
        };
    }

    private particularMessageConfig(message: PresetMessageFormData) {
        switch (message.type) {
            case MessageType.RAW:
                return {};
            case MessageType.OPTION:
                return { options: message.options, fieldToSelect: message.fieldToSelect };
            case MessageType.FILE:
                return {
                    messageConfig: {
                        errorText: message.messageConfig?.errorText,
                        maxSize: message.messageConfig?.maxSize
                    }
                };
            case MessageType.TEXT:
                return {
                    messageConfig: {
                        errorText: message.messageConfig?.errorText,
                        regexType: message.messageConfig?.regexType,
                        identifier: message.messageConfig?.identifier
                    }
                };
            default:
                return {};
        }
    }
}
