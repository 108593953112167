import { Prisma, ModelVersion, ClientPlan, $Enums } from '@prisma/client';

import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { rollbar } from '../utils/rollbar';
import { BaseService } from './base-service';

type chatConfigurationDoc = {
    filename: string;
    url: string;
};

export type ChatConfigurationWithDocs = Prisma.ChatConfigurationGetPayload<{
    include: { presetMessages: true };
}> & { documents?: chatConfigurationDoc[]; rejectedDocs?: string[]; clientPlan?: ClientPlan };

export interface ChatConfigurationService {
    getConfigurationByName(
        name: string,
        getDocs?: boolean,
        getPlan?: boolean
    ): Promise<BaseResponse<ChatConfigurationWithDocs>>;
    getConfigurationById(
        id: string,
        getDocs?: boolean,
        getPlan?: boolean
    ): Promise<BaseResponse<ChatConfigurationWithDocs>>;
    postConfiguration(
        clientName: string,
        model: ModelVersion,
        documents: Blob[],
        deletedDocs?: string[],
        context?: string,
        organizationName?: string,
        businessDescription?: string,
        knowledgeBaseDescription?: string,
        botTaskDescription?: string,
        supportEmail?: string,
        botName?: string,
        statusText?: string,
        callToAction?: string,
        inputPlaceholder?: string,
        contactCenterDisabledText?: string,
        answerTemperature?: number,
        questionTemperature?: number,
        showSourceDocs?: boolean,
        botIcon?: Blob,
        getDocs?: boolean
    ): Promise<BaseResponse<ChatConfigurationWithDocs>>;
    patchConfiguration(
        clientId: string,
        config: {
            model?: ModelVersion;
            context?: string;
            documents?: Blob[];
            deletedDocs?: string[];
            botName?: string;
            statusText?: string;
            callToAction?: string;
            inputPlaceholder?: string;
            contactCenterDisabledText?: string;
            answerTemperature?: number;
            questionTemperature?: number;
            showSourceDocs?: boolean;
            botIcon?: Blob;
            getDocs?: boolean;
            showableAtPosition?: number;
            contactCenterEnabled?: boolean;
        }
    ): Promise<BaseResponse<ChatConfigurationWithDocs>>;
    getFiles(clientId: string): Promise<BaseResponse<string[]>>;
}

export class ChatConfigurationImp extends BaseService implements ChatConfigurationService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        super('Chat-Configuration-Service');
        this.httpRequest = httpRequest;
    }

    async getConfigurationByName(
        name: string,
        getDocs?: boolean | undefined,
        getPlan?: boolean | undefined
    ): Promise<BaseResponse<ChatConfigurationWithDocs>> {
        return this.getConfigurationBy({ name }, getDocs, getPlan);
    }

    async getConfigurationById(
        id: string,
        getDocs?: boolean | undefined,
        getPlan?: boolean | undefined
    ): Promise<BaseResponse<ChatConfigurationWithDocs>> {
        return this.getConfigurationBy({ clientId: id }, getDocs, getPlan);
    }

    private async getConfigurationBy(
        criteria: any,
        getDocs: boolean = false,
        getPlan: boolean = false
    ): Promise<BaseResponse<ChatConfigurationWithDocs>> {
        try {
            const response = await this.httpRequest.get<ChatConfigurationWithDocs>({
                url: generateUrl(`api/chat/config`, { ...criteria, docs: getDocs, plan: getPlan })
            });

            if (response.error) {
                console.error(response.error);
                rollbar.error(response.error, {
                    custom: { ...criteria, docs: getDocs, plan: getPlan }
                });
            }
            return response;
        } catch (error: any) {
            console.error('Error fetching data', error);
            rollbar.error('[Chat-config] Error fetching data: ', {
                custom: { error, client: name }
            });
            return { body: {} as ChatConfigurationWithDocs, error: error, sessionExpired: false };
        }
    }
    async patchConfiguration(
        clientId: string,
        config: {
            model?: $Enums.ModelVersion;
            context?: string;
            documents?: Blob[];
            deletedDocs?: string[];
            botName?: string;
            statusText?: string;
            callToAction?: string;
            inputPlaceholder?: string;
            contactCenterDisabledText?: string;
            answerTemperature?: number;
            questionTemperature?: number;
            showSourceDocs?: boolean;
            botIcon?: Blob;
            getDocs?: boolean;
            showableAtPosition?: number;
            contactCenterEnabled?: boolean;
        }
    ): Promise<BaseResponse<ChatConfigurationWithDocs>> {
        const request = () =>
            this.httpRequest.patch<ChatConfigurationWithDocs>({
                url: generateUrl(`api/client/${clientId}/chat/config`),
                body: config
            });

        return this.tryRequest<ChatConfigurationWithDocs>(request);
    }
    async postConfiguration(
        clientName: string,
        model: ModelVersion,
        documents: Blob[],
        deletedDocs?: string[],
        context?: string,
        organizationName?: string,
        businessDescription?: string,
        knowledgeBaseDescription?: string,
        botTaskDescription?: string,
        supportEmail?: string,
        botName?: string,
        statusText?: string,
        callToAction?: string,
        inputPlaceholder?: string,
        contactCenterDisabledText?: string,
        answerTemperature?: number,
        questionTemperature?: number,
        showSourceDocs?: boolean,
        botIcon?: Blob | null,
        getDocs: boolean = false
    ): Promise<BaseResponse<ChatConfigurationWithDocs>> {
        const form = new FormData();

        form.append('client', clientName);
        form.append('model', model);

        const appendIfDefined = (value: string | undefined, key: string) => {
            if (value !== undefined) form.append(key, value);
        };

        appendIfDefined(context, 'context');
        appendIfDefined(organizationName, 'organizationName');
        appendIfDefined(businessDescription, 'businessDescription');
        appendIfDefined(knowledgeBaseDescription, 'knowledgeBaseDescription');
        appendIfDefined(botTaskDescription, 'botTaskDescription');
        appendIfDefined(supportEmail, 'supportEmail');
        appendIfDefined(botName, 'botName');
        appendIfDefined(statusText, 'statusText');
        appendIfDefined(callToAction, 'callToAction');
        appendIfDefined(inputPlaceholder, 'inputPlaceholder');
        appendIfDefined(contactCenterDisabledText, 'contactCenterDisabledText');

        if (
            typeof answerTemperature === 'number' &&
            answerTemperature >= 0 &&
            answerTemperature <= 1
        )
            form.append('answerTemperature', answerTemperature.toString());
        if (
            typeof questionTemperature === 'number' &&
            questionTemperature >= 0 &&
            questionTemperature <= 1
        )
            form.append('questionTemperature', questionTemperature.toString());
        if (typeof showSourceDocs === 'boolean')
            form.append('showSourceDocs', showSourceDocs.toString());

        if (Array.isArray(documents) && documents.length)
            documents.forEach((document: Blob, index: number) =>
                form.append(`document${index + 1}`, document)
            );

        if (Array.isArray(deletedDocs) && deletedDocs.length)
            form.append('deletedDocs', JSON.stringify(deletedDocs));

        if (botIcon !== undefined) form.append('botIcon', botIcon || 'null');

        try {
            // TODO: Use FetchHttpRequest service.
            // For some reason FetchHttpRequest incorrectly assigns 'Content-Type' header, not allowing to correctly create the chat configuration.
            const response = await fetch(generateUrl(`api/chat/setup`, { docs: getDocs }), {
                method: 'POST',
                body: form
            });

            const data = await response.json();

            if (!response.ok) throw new Error(data.message);

            return { body: data as ChatConfigurationWithDocs, sessionExpired: false };
        } catch (error) {
            console.error('Error creating chat configuration: ', error);
            return { body: {} as ChatConfigurationWithDocs, error: error, sessionExpired: false };
        }
    }
    async getFiles(clientId: string): Promise<BaseResponse<string[]>> {
        try {
            const response = await fetch(generateUrl(`api/client/${clientId}/chat/config/files`), {
                method: 'GET'
            });

            return { body: await response.json(), sessionExpired: false };
        } catch (error) {
            console.error('Error when retrieving client files', error);
            return { body: [], error: error, sessionExpired: false };
        }
    }
}
