import { BaseResponse } from './fetch-methods';
import { rollbar } from '../utils/rollbar';

export abstract class BaseService {
    constructor(private rollbarPrefix: string) {}

    protected async tryRequest<T>(req: () => Promise<BaseResponse<T>>): Promise<BaseResponse<T>> {
        try {
            const response = await req();
            if (response.error) console.error(response.error);
            return response;
        } catch (error) {
            console.error(`[${this.rollbarPrefix}] Error during request:`, error);
            rollbar.error(`[${this.rollbarPrefix}] Error during request: `, { custom: { error } });

            return { body: {} as T, error: error, sessionExpired: false };
        }
    }
}
