import { useRouter } from 'next/router';
import React, { useContext, createContext } from 'react';

import { AuthService, AuthServiceImp } from '../services/auth-service';
import {
    ChatConfigurationImp,
    ChatConfigurationService
} from '../services/chat-configuration-service';
import { ChatService, ChatServiceImp } from '../services/chat-service';
import { ClientService, ClientServiceImp } from '../services/client-service';
import { FetchHttpRequest, HttpRequest } from '../services/fetch-methods';
import { NamespaceService, NamespaceServiceImp } from '../services/namespace-service';
import {
    PresetMessagesService,
    PresetMessagesServiceImp
} from '../services/preset-messages-service';
import { NavigationRouter } from '../utils/navigation-router';
import { AnalyticsService, AnalyticsServiceImp } from '../services/analytics-service';
import { ReportService, ReportServiceImp } from '../services/report-service';
import { ClientPlanService, ClientPlanServiceImp } from '../services/client-plan-service';
import { MessageService, MessageServiceImp } from '../services/message-service';

interface ProviderContext {
    chatService: ChatService;
    authService: AuthService;
    configService: ChatConfigurationService;
    namespaceService: NamespaceService;
    clientService: ClientService;
    clientPlanService: ClientPlanService;
    presetMessagesService: PresetMessagesService;
    analyticsService: AnalyticsService;
    reportService: ReportService;
    messageService: MessageService;

    navigationRouter: NavigationRouter;
}

export const GlobalContext = createContext<any>(undefined);

export function GlobalProvider({ children }: any) {
    const fetchHttpRequest: HttpRequest = new FetchHttpRequest();

    const chatService: ChatService = new ChatServiceImp(fetchHttpRequest);
    const authService: AuthService = new AuthServiceImp(fetchHttpRequest);
    const configService: ChatConfigurationService = new ChatConfigurationImp(fetchHttpRequest);
    const namespaceService: NamespaceService = new NamespaceServiceImp(fetchHttpRequest);
    const clientService: ClientService = new ClientServiceImp(fetchHttpRequest);
    const clientPlanService: ClientPlanServiceImp = new ClientPlanServiceImp(fetchHttpRequest);
    const presetMessagesService: PresetMessagesService = new PresetMessagesServiceImp(
        fetchHttpRequest
    );
    const analyticsService: AnalyticsService = new AnalyticsServiceImp(fetchHttpRequest);
    const reportService: ReportService = new ReportServiceImp(fetchHttpRequest);
    const messageService: MessageService = new MessageServiceImp(fetchHttpRequest);

    const navigationRouter = new NavigationRouter(useRouter());

    return (
        <GlobalContext.Provider
            value={{
                chatService,
                authService,
                configService,
                namespaceService,
                clientService,
                clientPlanService,
                presetMessagesService,
                analyticsService,
                reportService,
                navigationRouter,
                messageService
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
}

export const useGlobalContext = (): ProviderContext => useContext(GlobalContext);
